html, body, .App, #root {
    /* height: 100%; */
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.app-logo-link {
    height: 150px;
    width: 100%;
    text-align: center;
}

.content-tab {
    background-color: #eeeeff;
    /* height: 100%; */
}

.loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%
}

.hover-btn {
    visibility: hidden;
}

.table-hover tbody tr:hover td {
    background: #0275d8;
    color: #fff;
    font-size: larger;
}

.table-row:hover .hover-btn {
    visibility: visible;
    border: none;
    border-radius: 30%;
    color: #000;
    background-color: #fff;
}

.table-row:hover .table-row-stock {
    font-size: xx-large;
}

.sticky-left {
    position: fixed;
    width: 17%; /*2/12 = 16.6*/
    /* width: 25%; */
}

.edit-apply-cancel {
    display: flex;
}

.edit-apply-cancel .btn {
    flex: 1;
}

.input-number-stock {
    width: 6rem;
    border-radius: 3px;
    border: none;
    font-size: 2.5rem;
}

.add-product-btn {
    background: none;
    border: none;
}

.add-product-btn:active {
    border: none !important;
}

.add-product-btn:focus {
    border: none !important;
}

.add-item-btn {
    position: fixed;
    border: none;
    color: #0275d8;
    bottom: 20px;
    right: 20px;
    background: none;
}

.add-item-btn:hover {
    transform: scale(1.2);
    transition-duration: 0.3s;
}

.add-item-btn:focus {
    border: none;
    outline: none;
}

.add-address-btn {
    position: absolute;
    border: none;
    color: #0275d8;
    bottom: 0px;
    right: 20px;
    background: none;
}

.add-address-btn:hover {
    transform: scale(1.2);
    transition-duration: 0.3s;
}

.add-address-btn:focus {
    border: none;
    outline: none;
}

.btn-style {
    border: none;
    outline: none;
    background: none;
}

.btn-style:focus {
    border: none;
    outline: none;
}

.btn-style:hover {
    transform: scale(1.2);
    transition-duration: 0.3s;
}

.fab-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 10;
    cursor: pointer;
}

.fab-options {
    list-style-type: none;
    margin: 0;

    position: absolute;
    bottom: 50px;
    right: 0;

    opacity: 0;

    transition: all 0.3s ease;
    transform: scale(0);
    transform-origin: 85% bottom;
}

.fab:hover + .fab-options, .fab-options:hover {
    opacity: 1;
    transform: scale(1);
}

.fab-options li {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
}

.fab-label {
    color: #eeeeff;
    padding: 10px 15px;
    align-self: center;
    white-space: nowrap;
    border-radius: 5px;
    font-size: 16px;
    background: #5cb85c;
    box-shadow: 0 6px 20px rgba(0,0,0,0.5);
}

.login-page-image {
    margin: 0;
    padding: 0;
    height: 100vh;
    background-image: url('./assets/images/background.jpg');
    background-size: cover;
    background-position: center;
}

.login-card {
    position: absolute;
    width: 350px;
    margin: auto;
    top: 20%; left: 0; right: 0; bottom: 0;
}

.tableFixHead          { overflow-y: auto; height: 14rem }
.tableFixHead thead th { position: sticky; top: 0; }

/* Just common table stuff. Really. */
table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px; }
th     { background:#5bc0de; color: #eeeeff; }


/* Utilities */
.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* gap: 2em; */
}